import cn from 'classnames';

import type {PillComponent} from '@/types';
import {ColorSchemeEnum} from '@/enums';
import {pillSchemeClasses} from '@/stylesheets/brochureV2/colorSchemes';

/**
 * @deprecated
 */
const Pill = ({text, colorScheme}: PillComponent) => {
  const currentScheme: ColorSchemeEnum = colorScheme || ColorSchemeEnum.Light;
  const pillClasses = pillSchemeClasses[currentScheme];

  return (
    <span
      className={cn(
        'inline-block uppercase font-bold text-xs py-1 px-2 rounded',
        pillClasses,
      )}
    >
      {text}
    </span>
  );
};

export default Pill;
